import * as React from 'react';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ru_RU from './ru_RU.json';
import en_EN from './en_EN.json';

const defaultLocale = 'en_EN';

export const languages = [
  { label: 'EN', locale: 'en_EN' },
  { label: 'RU', locale: 'ru_RU' }
];

const resources = {
  ru_RU: ru_RU,
  en_EN: en_EN,
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: defaultLocale,
    compatibilityJSON: 'v3',
    resources,
    //lng: defaultLocale,// default language to use.
  });

export function getCurrentLanguage(locale){
  locale = locale ?? defaultLocale;
  locale = locale.replace('-', '_').replace('en_US', 'en_EN');
  //console.log('getCurrentLanguage', locale)
    for(let lang in languages){
      if(languages[lang].locale.toLocaleLowerCase() == locale.toLocaleLowerCase() || languages[lang].label.toLocaleLowerCase() == locale.toLocaleLowerCase()){
        //console.log(languages[lang]);
        return languages[lang];
      }
    }
    return defaultLocale;
  }

export default function LanguageDropdown(props) {
  const [ lang, setLang ] = React.useState(getCurrentLanguage(i18n.language).locale);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  React.useEffect(() => {
    if(lang)
      i18n.changeLanguage(lang);
  }, [lang])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLang = (targetLang) => () => {
    setLang(targetLang);
    handleClose();
  };

  if (!lang) {
    return (
      <Box
        data-screenshot="toggle-lang"
        sx={(theme) => ({
          verticalAlign: 'bottom',
          display: 'inline-flex',
          width: '2.25rem',
          height: '2.25rem',
          borderRadius: (theme.vars || theme).shape.borderRadius,
          border: '1px solid',
          borderColor: (theme.vars || theme).palette.divider,
        })}
      />
    );
  }
  return (
    <React.Fragment>
      <Button
        data-screenshot="toggle-lang"
        onClick={handleClick}
        disableRipple
        size="small"
        aria-controls={open ? 'color-scheme-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        {...props}
      >
        {getCurrentLanguage(lang).label} 
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            variant: 'outlined',
            elevation: 0,
            sx: {
              my: '4px',
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >{languages.map(item => {
        return (
            <MenuItem selected={lang === item.locale} onClick={handleLang(item.locale)} >{item.label}</MenuItem>
        )
      })}


      </Menu>
    </React.Fragment>
  );
}
