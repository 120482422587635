import * as React from 'react';
import Button from '@mui/material/Button';
import Blog from './blog/Blog';
import logo from './logo.svg';
import './App.css';

function App() {
  React.useEffect(() => {
    //document.title = "canfiti🎉"
  }, [])
  return (
    <Blog></Blog>
  );
}

export default App;
