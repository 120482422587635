import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import AppAppBar from './components/AppAppBar';
import MainContent from './components/MainContent';
import Latest from './components/Latest';
import Footer from './components/Footer';
import AppTheme from '../shared-theme/AppTheme';
import PrivacyPolicy from './components/PrivacyPolicy';

export default function Blog(props) {
  const [page, setPage] = React.useState("PrivacyPolicy");

  React.useEffect(() => {
    document.title = "canfiti🎉"
  }, [])
  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      <AppAppBar />
      <Container
        maxWidth="lg"
        component="main"
        sx={{ display: 'flex', flexDirection: 'column', my: 16, gap: 4 }}
      >
        {page == "PrivacyPolicy" ? <PrivacyPolicy /> :
          <Box>
            <MainContent />
            <Latest />
          </Box>
        }
      </Container>
      {page == "PrivacyPolicy" ? <></> :<Footer />}


    </AppTheme>
  );
}
