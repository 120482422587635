import * as React from 'react';
import { useTranslation } from "react-i18next";
import Typography from '@mui/material/Typography';


export default function PrivacyPolicy() {
  const { t } = useTranslation();
  if (t("LANG") == "ru_RU")
    return (
      <Typography color="text.primary" size="big">

        <Typography variant="h6" color="text.primary" size="big">ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ ДЛЯ ПРИЛОЖЕНИЯ "canfiti🎉"</Typography>

        <Typography>
          Приложение "canfiti🎉" (далее именуемое "приложение") предназначено для использования "как есть", без каких-либо гарантий.
          Приложение не собирает личную информацию.
          Приложение может использовать данные о местоположении для прямого подключения к устройствам Bluetooth или WiFi ELM327 (необходимо, поскольку прямое подключение к устройству ELM327 через Bluetooth или WiFi ELM327 является основным способом подключения к транспортному средству) и для расчета скорости. Данные о местоположении не передаются приложением за пределы устройства.
          Приложение использует сторонний сервис "Сервисы Google Play", который может собирать информацию, используемую для идентификации пользователя. Ссылка на политику конфиденциальности «Сервисов Google Play»: https://policies.google.com/privacy
        </Typography>
        <br />
        <Typography variant="h6" color="text.primary" size="big">КОНФИДЕНЦИАЛЬНОСТЬ САЙТА CANFITI.COM</Typography>
        <Typography>
          Сайт canfiti.com (далее именуемый «сайт») может использовать файлы cookie для обеспечения корректной работы сайта. Файл cookie — это небольшой фрагмент данных, хранящийся на компьютере пользователя, позволяющий сайту распознавать этот компьютер при последующих посещениях.
          Сайт может ссылаться на внешние сайты, которые управляются третьими лицами. Мы не контролируем и не несем ответственности за какой-либо контент или услуги внешних сайтов. В случае перехода по внешним ссылкам пользователю предлагается ознакомиться с политикой конфиденциальности каждого внешнего сайта.
        </Typography>
        <br />
        <Typography variant="h6" color="text.primary" size="big">ИЗМЕНЕНИЯ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</Typography>
        <Typography>Политика конфиденциальности для приложения и веб-сайта может время от времени меняться. В случае изменений обновленная политика будет опубликована на этой странице. Заинтересованным лицам рекомендуется периодически просматривать эту страницу.</Typography>
      </Typography >
    );
  else
    return (
      <Typography color="text.primary" size="big">

        <Typography variant="h6" color="text.primary" size="big">PRIVACY POLICY FOR "canfiti🎉" APPLICATION</Typography>
        <Typography>
          "canfiti🎉" app (further referred as "the app") are intended for use as is, without any kind of warranty.
          The app do not collect personal information.
          The app may use location data for direct connection to Bluetooth or WiFi ELM327 devices (necessary as direct connection to ELM327 device via Bluetooth or WiFi ELM327 device is the primary method of connecting to vehicle) and for calculatiton of speed. Location data is not transmitted by the app to outside of the device.
          The app use third party service "Google Play Services" that may collect information used to identify the user. Link to "Google Play Services" privacy policy: https://policies.google.com/privacy
        </Typography>
        <br />
        <Typography variant="h6" color="text.primary" size="big">CANFITI.COM WEBSITE PRIVACY</Typography>
        <Typography>
          canfiti.com website (further referred as "the website") may use Cookies to allow the site to function properly. Cookie is small piece of data stored on user's computer allowing to the website to recognize this computer on subsequent visits.
          The website may link to external sites which are operated by third-parties. We have no control over and are not responsible for any content or services of external sites. In case of clicking external links, user is suggested to review privacy policy of each external website.
        </Typography>
        <br />
        <Typography variant="h6" color="text.primary" size="big">PRIVACY POLICY CHANGES</Typography>
        <Typography>Privacy policy for the app and for the website may change from time to time. In case of changes, updated policy will be posted on this page. Concerned persons are advised to review this page periodically.</Typography>
      </Typography >
    );
}
